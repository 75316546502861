@import './index.scss';
@import './styles/base.scss';

.imageWrapper {
  height: 714px;
  background-color: #1a1a1a;
}

.headerBackground {
  height: 115px;
}

@media only screen and (max-width: 768px) {
  .headerBackground {
    height: 80px;
  }
}

.container {
  display: grid;
  margin: auto;
  max-width: $content-width;
  padding-left: 15px;
  padding-right: 15px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 115px 876px auto auto;
  grid-column-gap: 30px;
  grid-template-areas:
    'h h h h h h h h h h h h'
    'p p p p p i i i i i i i'
    'c c c c c c c c c c c c'
    'f f f f f f f f f f f f';
}

@media only screen and (max-width: 768px) {
  .container {
    // Set up the container for the site content
    grid-template-rows: 80px 811px auto auto;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    grid-column-gap: 0px;
    grid-template-columns: 1;
    grid-template-rows: 115px auto auto auto auto;
    grid-template-areas:
      'h h h h h h h h h h h h'
      'i i i i i i i i i i i i'
      'p p p p p p p p p p p p'
      'c c c c c c c c c c c c'
      'f f f f f f f f f f f f';
  }
}
