@import "../../index.scss";
@import "../../styles/base.scss";

.appInfo {
  grid-area: i;
  display: flex;
  padding-top: 140px;
  align-content: flex-start;
  flex-direction: column;
}

@media only screen and (max-width: 992px) {
  .appInfo {
    padding-top: 0;
    justify-content: center;
  }
}

.appNamePriceContainer {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.appName {
  color: $app-title-color;
  font-size: 80px;
  font-weight: 800;
}

.store {
  margin-top: 16px;
  width: 190px;
  height: 65px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0.783403px 0.783403px 0px,
    rgb(0 0 0 / 1%) 0px 1.92107px 1.92107px 0px, rgb(0 0 0 / 2%) 0px 3.63429px 3.63429px 0px,
    rgb(0 0 0 / 4%) 0px 6.35486px 6.35486px 0px, rgb(0 0 0 / 7%) 0px 11.0474px 11.0474px 0px,
    rgb(0 0 0 / 13%) 0px 20.2497px 20.2497px 0px, rgb(0 0 0 / 25%) 0px 40px 40px 0px;
  border-radius: 12px;

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: center;
  &:hover {
    box-shadow: none;
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 768px) {
  .appNamePriceContainer {
    width: 100%;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
  }

  .appName {
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .appStore {
    transform: none;
  }
}

.appDescriptionContainer {
  font-size: 2.5rem;
  width: 100%;
  align-items: flex-start;
  margin-top: 45px;
  flex: 0 1 auto;
  line-height: 1.5;
}

.appDescription {
  color: $app-description-color;
}

@media only screen and (max-width: 992px) {
  .appDescription {
    text-align: center;
  }
}

.downloadButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  filter: $drop-shadow;
}

.downloadButtonsContainer .downloadButton {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 190px;

  margin-bottom: 18px;
  margin-right: 18px;
}

@media only screen and (max-width: 992px) {
  .downloadButtonsContainer {
    text-align: center;
    justify-content: center;
  }
}

.iphonePreview {
  grid-area: p;
  background-image: url("white.png");
  background-size: 400px auto;
  background-repeat: no-repeat;
}

.iphoneScreen {
  width: 349px;
  -webkit-clip-path: url(#screenMask);
  clip-path: url(#screenMask);
  margin-left: 26px;
  margin-top: 23px;
}

@media only screen and (max-width: 1070px) {
  .iphonePreview {
    background-size: 370px auto;
  }

  .iphoneScreen {
    width: 322px;
    margin-left: 24px;
    margin-top: 22px;
  }
}

@media only screen and (max-width: 992px) {
  .iphonePreview {
    display: flex;
    background-image: none;
    justify-content: center;
  }

  .spline {
    height: 800px !important;
  }
}


.countryWrapper {
  display: flex;
  align-items: center;
  margin: 0 12px 12px 0;
  background-color: #111;
  padding: 10px 16px;
  border-radius: 12px;
}

.flag {
  // padding: 10px;
  // border-radius: 50%;
  // background-color: #111;
  margin-right: 12px;
}

.name {
  font-weight: 500;
  color: white;
}

@media only screen and (max-width: 992px) {
  .availableCountries {
    justify-content: space-around;
    // flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    // width: 100%;
  }

  .countryWrapper {
    background-color: black;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 4px;
    // &:not(:last-of-type) {
    //   .name {
    //     display: none;
    //   }
    // }
    // &:last-of-type {
    //   .flag {
    //     margin-right: 8px;
    //   }
    // }
  }

  .flag {
    margin-right: 8px;
    background-color: transparent;
    padding: 0;
    // font-size: 16px;
  }

  .name {
    color: white;
    // display: none;
  }
}
