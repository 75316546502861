$body-color: #ffffff;

$device-color: '../assets/white.png';

$accent-color: #1d63ea;

$header-title-color: #ffffff;
$app-title-color: #ffffff;
$app-price-color: #ffffff;
$app-description-color: #ffffff;

$feature-title-color: #fff;
$feature-text-color: #666666;
$footer-text-color: #666666;

$header_color: #000000;
$header_transparency: 0.1;

$image-overlay-color: #363b3d;
$image-overlay-transparency: 0.8;

$feature-icons-foreground-color: #fff;
$feature-icons-background-color: #000;

$social-icons-foreground-color: #666666;
$social-icons-background-color: #e6e6e6;
