@import '../../index.scss';
@import '../../styles/base.scss';

header {
  grid-area: h;
  display: flex;
  margin-right: -15px;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 115px;
  margin-right: 30px;
}

.logo > p {
  color: $header-title-color;
  white-space: nowrap;
  display: flex;
  font-weight: 800;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 80px;
    margin-right: 0px;
  }

  .logo > p {
    display: none;
  }
}

.headerIcon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .divider {
    position: relative;
    min-width: 1px;
    max-width: 1px;
    background-color: rgba(127, 127, 127, 0.2);
    display: inline-block;
    margin-left: 15px;
  }
}

.scroll {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  height: 115px;
  //margin-left: 15px;

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media only screen and (max-width: 768px) {
  .scroll {
    height: 80px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: -60px;
    justify-content: flex-start;
  }
}

.scroll::-webkit-scrollbar {
  display: none;
}

nav > ul {
  color: #fff;
  display: flex;
  list-style-type: none;
}

nav > ul li {
  padding-left: 30px;
}

@media only screen and (max-width: 768px) {
  nav > ul li {
    padding-left: 20px;
  }
}

nav > ul li:first-child {
  padding-left: 0px;
  padding-left: 15px;
}

nav > ul li:last-child {
  padding-right: 15px;
}

nav > ul li a {
  font-size: 16px;
}

nav > ul li a:link,
nav > ul li a:visited {
  text-decoration: none;
  color: rgba($header-title-color, 0.6);
}

nav > ul li a:hover,
nav > ul li a:active {
  text-decoration: none;
  color: rgba($header-title-color, 1);
}

.appIconShadow {
  display: flex;
  filter: $drop-shadow;
}
