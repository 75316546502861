@import '../../index.scss';
@import '../../styles/base.scss';

footer {
  grid-area: f;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footerText {
  color: $footer-text-color;
  display: block;
  line-height: 1.5;
  width: 100%;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

.footerLinks {
  display: flex;
  text-align: center;
  padding-bottom: 70px;
}

.footerLinks a {
  margin-right: 30px;
}

.footerLinks a:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 768px) {
  .footerLinks {
    flex-direction: column;
  }

  .footerLinks a {
    justify-content: stretch;
    margin-right: 0px;
    margin-top: 20px;
  }

  .footerLinks a:first-child {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .footerText {
    color: $footer-text-color;
    display: block;
    line-height: 1.5;
    width: 100%;
    text-align: center;
    padding-top: 54px;
    padding-bottom: 61px;
  }

  .footerIcons {
    padding-bottom: 70px;
    display: flex;
  }
}
